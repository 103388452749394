<template>
  <div class="container sidebar-container" :style="cssProps">
    <!-- SIDEBAR -->
    <div
      :class="sidebarClass"
      tabindex="-1"
      id="offcanvasId"
      data-bs-keyboard="true"
      data-bs-backdrop="false"
      aria-labelledby="offcanvasId"
      @click="toggleSidebar()"
      :style="{
        height: sidebarHeight,
      }"
    >
      <div class="offcanvas-header align-top">
        <h5 class="offcanvas-title">{{ title }}</h5>
        <div class="text-left">
          <button
            type="button"
            class="btn ps-2"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            HIDE &#8601;
          </button>
        </div>
      </div>
      <div class="offcanvas-body">
        <div id="sidebar">
          <div class="row">
            <div
              class="col-lg-6 col-xl-5 col-md-8 col-sm-10 justify-content-start"
            >
              <div class="row">
                <div class="col-12 mb-4">
                  <h2>
                    Jung In Seo and Dalma Földesi are two multidisciplinary
                    designers who design objects, tools, environments, and
                    processes by carefully translating between the virtual and
                    the physical. They explore the interlinked and recursive
                    realities that straddle the two realms to entwine
                    traditional craft and advanced manufacturing, physical and
                    algorithmic materiality, and haptic and immaterial
                    experience.
                  </h2>
                </div>
                <div class="col-12">
                  <h3>
                    They previously held residencies at Pratt University's
                    Consortium for Research of Robotics and at Autodesk's BUILD
                    Space. They design and make hybrid objects in Brooklyn, NY.
                  </h3>
                </div>
                <div class="col-12 mt-4">
                  <!-- <h3 class="offcanvas-title mt-1">
                    <a
                      class="inline-link"
                      href="https://www.linkedin.com/in/dalmafoldesi/"
                      target="_blank"
                      >Youtube &#8599;</a
                    >
                  </h3> -->
                  <h3 class="offcanvas-title mt-1">
                    <a
                      class="inline-link"
                      href="https://www.instagram.com/hybrid.obj/"
                      target="_blank"
                      >Instagram &#8599;</a
                    >
                  </h3>
                  <h3 class="offcanvas-title mt-1 mb-4">
                    <a
                      class="inline-link"
                      href="mailto:hello@hybrid-objects.com"
                      target="_blank"
                      >hello@hybrid-objects.com &#9993;</a
                    >
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-xl-6 offset-xl-1 col-md-8 col-sm-10">
              <img
                :src="aboutImage"
                class="img-fluid"
                :style="{
                  width: '100% !important',
                  height: 'auto',
                  maxHeight: '100% !important',
                }"
              />
            </div>
          </div>
          <!-- DESCRIPTION -->
          <div class="row mb-4"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  props: ["title", "offset", "z", "sidebarOpen"],
  components: {},
  data: function () {
    return {
      aboutImage: require("@/assets/images/A Palace for the Eastern Bluebird/3.jpg"),
      "--offset": "5px",
      sidebarClass: "offcanvas offcanvas-bottom p-3",
      sidebarHeight:
        window.innerWidth > 768
          ? window.innerHeight - window.navbarDims[1] + "px"
          : window.innerHeight - window.navbarDims[0] + "px",
    };
  },
  methods: {
    toggleSidebar() {
      // var offcanvas_el = document.getElementById(this.index);
      // console.log(offcanvas_el);
      //   var offc = new Offcanvas(offcanvas_el, {backdrop: false})
      //   offc.hide();
    },
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
      this.sidebarHeight =
        window.innerWidth > 768
          ? window.innerHeight - window.navbarDims[1] + "px"
          : window.innerHeight - window.navbarDims[0] + "px";
    },
  },
  mounted() {
    if (this.sidebarOpen == true) {
      this.sidebarClass = "offcanvas show offcanvas-bottom p-3";
    }

    window.addEventListener("resize", this.onResize);
    // this.emitter.on("showInfo", () => {
    //   this.sidebarClass = 'offcanvas show offcanvas-bottom p-2';
    // });
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    cssProps: function () {
      return {
        "--width": "50px",
        "--offset": this.offset + "px",
        "--offsetOpen": parseInt(this.offset) + "px",
        "--zIndex": this.z,
        "--pointerCursor": "url(" + window.pointerCursor + ") 6 6, pointer",
        "--cameraCursor": "url(" + window.cameraCursor + ") 6 6, pointer",
        "--arrowCursor": "url(" + window.arrowCursor + ") 6 6, auto",
        "--waveCursor": "url(" + window.waveCursor + ") 6 6, auto",
      };
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./../assets/scss/_variables.scss";

.backdrop {
  display: none !important;
}

.sidebar-container {
  cursor: var(--waveCursor);
}

.offcanvas {
  // box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.11);
  border-top: 1px solid black;
  margin-left: var(--offsetOpen);
  background-color: white;
  width: 100vw;
  transition-duration: 0.8s;
  animation-timing-function: ease-in-out;
  z-index: var(--zIndex);
}
.offcanvas-title {
  text-transform: uppercase;
  line-height: 110%;
  color: var(--col2);
  font-weight: 500;
}

.logoImg {
  width: 90%;
  height: auto;
  margin-bottom: 25px;
}

img:hover {
  cursor: var(--cameraCursor);
}

button:hover {
  cursor: var(--pointerCursor);
}
</style>