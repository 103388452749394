<template>
  <router-view />
</template>



<style lang="scss">
@import url("https://use.typekit.net/mle1sit.css");

@font-face {
  font-family: "Ekstra";
  src: url("~@/assets/fonts/GTF_Ekstra_Regular_WEB/Ekstra-Regular.eot");
  src: url("~@/assets/fonts/GTF_Ekstra_Regular_WEB/Ekstra-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/GTF_Ekstra_Regular_WEB/Ekstra-Regular.woff2")
      format("woff2"),
    url("~@/assets/fonts/GTF_Ekstra_Regular_WEB/Ekstra-Regular.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: "Rader";
//   src: url("~@/assets/fonts/PPRader_Trial/PPRader-Regular.eot");
//   src: url("~@/assets/fonts/PPRader_Trial/PPRader-Regular.eot?#iefix")
//       format("embedded-opentype"),
//     url("~@/assets/fonts/PPRader_Trial/PPRader-Regular.woff2")
//       format("woff2"),
//     url("~@/assets/fonts/PPRader_Trial/PPRader-Regular.woff")
//       format("woff");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: "Rader";
  src: url("~@/assets/fonts/PPRader_Trial/PPRader-Regular.eot");
  src: url("~@/assets/fonts/PPRader_Trial/PPRader-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/PPRader_Trial/PPRader-Regular.woff2")
      format("woff2"),
    url("~@/assets/fonts/PPRader_Trial/PPRader-Regular.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Formula";
  src: url("~@/assets/fonts/Formula_Trial/PPFormula-Light.eot");
  src: url("~@/assets/fonts/Formula_Trial/PPFormula-Light.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/Formula_Trial/PPFormula-Light.woff2")
      format("woff2"),
    url("~@/assets/fonts/Formula_Trial/PPFormula-Light.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Pleasure";
  src: url("~@/assets/fonts/Pleasure_Trial/PleasureStandardTrial-Regular.otf");
  src: url("~@/assets/fonts/Pleasure_Trial/PleasureStandardTrial-Regular.woff2")
      format("woff2"),
    url("~@/assets/fonts/Pleasure_Trial/PleasureStandardTrial-Regular.woff")
      format("woff");
  font-weight: light;
  font-style: normal;
  font-display: swap;
  
}

@font-face {
  font-family: "UltraSolar";
  src: url("~@/assets/fonts/Ultrasolar_Trial/UltraSolarTrial.otf");
  src: url("~@/assets/fonts/Ultrasolar_Trial/UltraSolarTrial-Normal.woff2")
      format("woff2"),
    url("~@/assets/fonts/Ultrasolar_Trial/UltraSolarTrial-Normal.woff")
      format("woff");
  font-weight: light;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "NeueMontreal";
  src: url("~@/assets/fonts/PPNeueMontreal_Trial/PPNeueMontreal-Book.otf");
  src: url("~@/assets/fonts/PPNeueMontreal_Trial/PPNeueMontreal-Book.woff2")
      format("woff2"),
    url("~@/assets/fonts/PPNeueMontreal_Trial/PPNeueMontreal-Book.woff")
      format("woff");
  font-weight: light;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "NeueMachina";
  src: url("~@/assets/fonts/PPNeueMachina/PPNeueMachina-Regular.otf");
  src: url("~@/assets/fonts/PPNeueMachina/PPNeueMachina-Regular.woff2")
      format("woff2"),
    url("~@/assets/fonts/PPNeueMachina/PPNeueMachina-Regular.woff")
      format("woff");
  font-weight: light;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "NeueMachina-UB";
  src: url("~@/assets/fonts/PPNeueMachina-Trial/PPNeueMachina-Ultrabold.otf");
  src: url("~@/assets/fonts/PPNeueMachina-Trial/PPNeueMachina-Ultrabold.woff2")
      format("woff2"),
    url("~@/assets/fonts/PPNeueMachina-Trial/PPNeueMachina-Ultrabold.woff")
      format("woff");
  font-weight: light;
  font-style: normal;
  font-display: swap;
}

@import "./assets/scss/_variables.scss";

a {
  color: black;
}

#app {
  font-family: "Ekstra", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  cursor: require("@/assets/icons/pointer.svg") 16 16, pointer !important;
}

////////////////////////////////////////////
// TYPE
////////////////////////////////////////////

html {
  scroll-behavior: smooth;

  font-size: 12px;
  @media (min-width: 800px) {
    font-size: 14px;
  }
  @media (min-width: 1200px) {
    font-size: 16px;
  }
}

// html {
//   font-size: 16px;
//   scroll-behavior: smooth;
//   // Magic:
//   @media (max-width: 1200px) {
//     font-size: calc(12px + .4vw);
//   }
// }

h1 {
  font-family: "Ekstra", Helvetica, Arial, sans-serif !important;
  font-size: 5.0rem !important;
  text-align: left !important;
}

// h2 {
//   font-family: "UltraSolar", Helvetica, Arial, sans-serif !important;
//   font-size: 1.563rem !important;
//   text-align: left !important;
// }

h2 {
  font-family: "NeueMachina", Helvetica, Arial, sans-serif !important;
  font-size: 1.563rem !important;
  text-align: left !important;
}

h3 {
  font-family: "NeueMachina", Helvetica, Arial, sans-serif !important;
  font-size: 1.25rem !important;
  font-weight: 500 !important;
  text-align: left !important;
  // font-size: 1rem !important;
}

h4 {
  font-family: "NeueMachina", sans-serif !important;
  // font-family: "neue-haas-grotesk-text", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 0.95rem !important;
  letter-spacing: 0.004rem;
  text-align: left !important;
}

h5 {
  font-family: "NeueMachina", sans-serif !important;
  // font-family: "neue-haas-grotesk-text", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 0.8rem !important;
  line-height: 0.9rem !important;
  text-align: left !important;
}

.portfolio-item {
  border-top: 1px solid black;
}


////////////////////////////////////////////
// BUTTONS
////////////////////////////////////////////

.btn {
  background-color: white !important;
  border-color:black!important;
  border-radius: 1rem !important;
  height: 1.5rem !important;
  line-height: 0.9rem !important;
  font-size: 1rem !important;
  text-align: center !important;
  // margin-top: 0px !important;
  padding: 0.25rem 0.25rem 0.5rem 0.5rem !important;
  // width: auto !important;
  text-transform: uppercase !important;
  // margin: auto !important;
  border-width: thin !important;
}

.btn:hover {
  background-color: $button-black !important;
  color: white !important;
}

button {
  vertical-align: top !important;
  // display: inline-block !important;
  margin-top: 0px !important;
  margin-right: 0 !important;
  right: 0;
  float: right;
}

////////////////////////////////////////////
// FORMS
////////////////////////////////////////////
input {
  border: none !important;
  border-bottom: 1px black solid !important;
  width: 100% !important;
  font-size: 1rem !important;
  line-height: 1.25rem !important;
}

input:focus, input:focus-visible {
  // border: none !important;
  outline: none !important;
  border-bottom: 1px black solid !important;
}

////////////////////////////////////////////
// INLINE LINKS
////////////////////////////////////////////

.inline-link {
  cursor: var(--pointerCursor);
  position: relative;
}

.inline-link:hover {
  text-decoration: none;
}

.inline-link-wavy {
  text-decoration: wavy underline 1px !important;
  cursor: var(--pointerCursor);
}

.inline-link::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.3s ease;
}

.inline-link:hover::before {
  transform: scaleX(1);
}

.inline-link-wavy:hover {
  // font-weight: bold;
  font-style: italic;
  text-decoration: wavy underline 1px !important;
}


</style>
