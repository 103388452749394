let p5;

export default {
    sketch: function (parent) {
        return function (_p5) {
            p5 = _p5;

            let canvas;

            let data = parent.$data;
            let w = parent.$data.width;
            let h = parent.$data.height;

            // let img;
            // let imgPath = parent.$data.imgPath;

            p5.preload = () => {
                // img = p5.loadImage(imgPath);
            }

            p5.setup = () => {
                canvas = p5.createCanvas(w, h);
                canvas.parent("sketchCanvas");

                console.log(data);

                p5.background(255);
            }

            p5.draw = () => {
                p5.fill(255);

                let mouseX = parent.$props.mouseX;
                let mouseY = parent.$props.mouseY;

                let radius = p5.map(Math.abs(p5.sin(p5.map(p5.second(), 0, 59, -1.0, 1.0))), 0.0, 1.0, 30, 100);

                p5.ellipse(mouseX - radius * 0.5, mouseY - radius * 0.5, radius, radius);
                // p5.image(img, mouseX - radius * 0.5, mouseY - radius * 0.5, radius, radius);
            }
        }
    }
}