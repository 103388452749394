import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.css";
import json from "@/assets/data/portfolio.json";

import config from "@/assets/config.js"
import axios from "axios";
import mitt from 'mitt';

const emitter = mitt();

let useGoogle = false;

// var fs = require('fs');

function setupData() {
    var gsheet_url = "https://sheets.googleapis.com/v4/spreadsheets/";
    gsheet_url += config.google.SPREADSHEET_ID;
    gsheet_url += "/values/Sheet1!A1:G15";
    gsheet_url += "?key=" + config.google.API_KEY;

    axios
    .get(gsheet_url)
    .then((response) => {
        parseGsheetData(response.data);
        const app = createApp(App).use(router);
        app.config.globalProperties.emitter = emitter;
        app.mount("#app");
    })
    .catch(error => console.log(error));
}

function parseGsheetData(curData) {
    // console.log(curData);
    window.projectLabels = curData.values[0];

    // Convert to JS object
    window.projectLabels = Object.assign({}, ...window.projectLabels.map((element, index) => {
      return({[element]: index});
    }));

    window.projects = curData.values.slice(1);

    // Convert to JS object
    window.projects = window.projects.map((element) => {
        return Object.assign({}, ...element.map((element, index) => {
            return ({[curData.values[0][index]]: element});
        }));
    });

    console.log(window.projects);

    loadImages();
}

function loadImages() {
    window.image_store = {};
    console.log(window.projects);
    let jsonFile = JSON.parse(JSON.stringify(json));

    window.projects.forEach((project) => {
        let curProjectTitle = project["Project Title"];
        window.image_store[curProjectTitle] = {};
        window.image_store[curProjectTitle].images = [];
        window.image_store[curProjectTitle].thumbnails = [];

        let curImagePath = curProjectTitle + "/";
        let curThumbnailPath = curProjectTitle + "/thumbnails/";

        window.image_store[curProjectTitle].images = loadFromPath(curImagePath);
        window.image_store[curProjectTitle].thumbnails = loadFromPath(curThumbnailPath, window.image_store[curProjectTitle].thumbnails);

        project.projectInfo = jsonFile.items[curProjectTitle].info;
    })

    console.log(window.image_store);
}

function loadImagesLocal() {
    window.image_store = {};

    let jsonFile = JSON.parse(JSON.stringify(json));
    // window.projects = jsonFile.items;
    // console.log(window.projects);

    window.projects = jsonFile.items.filter(function (obj) {
        return obj["visible"];
      });

    window.projects.forEach((project) => {
        let curProjectTitle = project["Project Title"];
        window.image_store[curProjectTitle] = {};
        window.image_store[curProjectTitle].images = [];
        window.image_store[curProjectTitle].thumbnails = [];

        let curImagePath = curProjectTitle + "/";
        let curThumbnailPath = curProjectTitle + "/thumbnails/";

        window.image_store[curProjectTitle].images = loadFromPath(curImagePath);
        window.image_store[curProjectTitle].thumbnails = loadFromPath(curThumbnailPath, window.image_store[curProjectTitle].thumbnails);

    })
}

function loadFromPath(curPath) {
    let counter = 1;
    let imgList = [];
    while (counter < 100) {
        try {
            let img = new Image();
            let reqPath = require("@/assets/images/" + curPath + counter + ".jpg");
            if (!reqPath) reqPath = require("@/assets/images/" + curPath + counter + ".png");
            img.src = reqPath;
            imgList.push(img);
            counter += 1;
        }
        catch (e) {
            // console.log("Cannot find image at " + curPath + counter);
            // console.log(e);
            break
        }
    }
    return imgList;
}

function loadCursors() {
    window.pointerCursor = require("@/assets/icons/pointer.svg");
    window.arrowCursor = require("@/assets/icons/arrow.svg");
    window.cameraCursor = require("@/assets/icons/camera.svg");
    window.waveCursor = require("@/assets/icons/wave.svg");
    window.closeCursor = require("@/assets/icons/close.svg");
}

if (useGoogle) {
    setupData();
}
else {
    loadImagesLocal();
    loadCursors();

    window.navbarDims = [110, 80];

    const app = createApp(App).use(router);
    app.config.globalProperties.emitter = emitter;
    app.mount("#app");
}




