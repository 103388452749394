<template>
  <div class="container-fluid p-4 pb-2 d-none d-md-block">
    <div class="sketch-canvas" id="sketchCanvas"></div>
  </div>
</template>

<script>
import p5 from "p5";
import canvasSketch from "../graphics/canvas-sketch";

export default {
  name: "CanvasGraphics",
  props: {
    mouseX: Number,
    mouseY: Number 
  },
  data: function () {
    this.sketch = null;
    return {
      width: window.innerWidth,
      height: window.innerHeight * 0.5,
      imgPath: require("@/assets/icons/wave.png")
    };
  },
  methods: {
    loadSketch: function () {
      this.sketch = new p5(canvasSketch.sketch(this));
    },
  },
  computed: {
    cssProps: function () {
      return {
        "--pointerCursor": "url(" + window.pointerCursor + ") 6 6, pointer",
      };
    },
  },
  created() {},
  mounted() {
    this.loadSketch();
  },
  unmounted() {},
  beforeUnmount() {
    // this.p5Sketch.remove();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "./../assets/scss/_variables.scss";

// .sketch-canvas {
//     border: 1px solid black;
// }
</style>