<template>
  <nav
    class="navbar fixed-top p-4"
    :style="{
      height: navbarHeight,
      width: '100%',
    }"
  >
    <div class="row" style="width: 100vw" :style="cssProps">
      <div class="d-flex col-md-6 col-sm-12 nav-item justify-content-start">
        <h2>
          &#9728;
          <router-link to="/">Hybrid Objects</router-link>
        </h2>
      </div>
      <div class="d-flex col-md-3 col-sm-12 nav-item justify-content-start">
        <h2>
          <a
            @click="onInfoClicked()"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasId"
            aria-controls="offcanvasId"
            >Info</a
          >,
          <a href="mailto:hello@hybrid-objects.com">Contact</a>
        </h2>
      </div>
      <div :class="justifyDANGClass + ' d-flex col-md-3 col-sm-12 nav-item'">
        <h2>
          <a href="https://www.instagram.com/hybrid.obj/" target="_blank"
            >Instagram</a
          >
          &#8599;
        </h2>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  props: ["name"],
  data: function () {
    return {
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      bNarrowScreen: false,
      navbarHeight:
        window.innerWidth > 768
          ? window.navbarDims[1] + "px"
          : window.navbarDims[0] + "px",
      justifyDANGClass:
        window.innerWidth < 768
          ? "justify-content-start"
          : "justify-content-end",
    };
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 768) {
        this.bNarrowScreen = true;
        this.justifyDANGClass = "justify-content-start";
      } else {
        this.bNarrowScreen = false;
        this.justifyDANGClass = "justify-content-end";
      }
      this.navbarHeight =
        window.innerWidth > 768
          ? window.navbarDims[1] + "px"
          : window.navbarDims[0] + "px";
    },
    onInfoClicked() {
      // console.log("Info clicked.");
      // this.emitter.emit("showInfo");
    },
  },
  computed: {
    cssProps: function () {
      return {
        "--width": "50px",
        "--pointerCursor": "url(" + window.pointerCursor + ") 6 6, pointer",
      };
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "./../assets/scss/_variables.scss";

a {
  color: black !important;
  text-decoration: none !important;
}
.navbar {
  background-color: white;
}
.nav-item {
  font-family: "PP Neue Machina";
  text-transform: uppercase;
}
.nav-item a:hover {
  /* text-decoration: underline; */
  /* border-bottom: 1px solid white;
    display: inline-block;
    line-height: 0.85; */
  color: black;
  text-decoration: underline !important;
  cursor: var(--pointerCursor);
}
a router-link-active {
  text-decoration: none !important;
  color: black;
}
</style>